import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/models/User/user.model';

@Component({
  selector: 'app-user-detail-dialog',
  templateUrl: './user-detail-dialog.component.html',
  styleUrls: ['./user-detail-dialog.component.scss']
})
export class UserDetailDialogComponent implements OnInit {

  user: User;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.user = this.data.user;
  }

  ngOnInit() {
  }

}
