import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-alert-document',
  templateUrl: './alert-document.component.html',
  styleUrls: ['./alert-document.component.scss']
})
export class AlertDocumentComponent implements OnInit {



    constructor(private dialogRef: MatDialogRef<AlertDocumentComponent>) { }

    ngOnInit() {
    }
    close(){
      this.dialogRef.close();
    }

}
