import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from 'src/services/auth/authentication.service';
import { Auth } from 'src/models/auth.model';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordDialogComponent } from './dialog/reset-password-dialog/reset-password-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "../../services/environment/environment.service";
import { Observable } from "rxjs";
import { UserService } from "../../services/user/user.service";
import { AlertComponent } from 'src/app/alert/alert.component';
import { ResetPasswordSuccessDialogComponent } from 'src/pages/login/dialog/reset-password-success-dialog/reset-password-success-dialog.component';
import { UtilService } from 'src/util/utilService';
import { AlertchangePassword } from 'src/app/alert-change-password/alert-change-password';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

// @Component({
//   selector: 'dialog-overview-example',
//   templateUrl: 'dialog-overview-example.html',
//   styleUrls: ['dialog-overview-example.css'],
// })

export class LoginComponent implements OnInit {

  userDoc: any;
  auth: Auth;
  constructor(private authenticationService: AuthenticationService, private dialog: MatDialog,
    private router: Router, private httpClient: HttpClient, private env: EnvironmentService,
    private userServ: UserService, private util: UtilService,private route: ActivatedRoute,
    @Inject('UserDoc') userDoc) {

    this.auth = new Auth();
    this.userDoc = userDoc;
  }

  ngOnInit() {
      this.route.params.subscribe(params => {
        this.auth.email = params['email'];
      })
    if(this.authenticationService.doYouRememberMe()){
      this.getUserProfile();
    }

    this.userServ.passBooleanToNavBar(false);
    this.initCanRetryLogin();
  }

  initCanRetryLogin() {
    let canRetryLogin = localStorage.getItem(AuthenticationService.CAN_RETRY_LOGIN_PROPERTY);
    if (canRetryLogin == undefined) {
      localStorage.setItem(AuthenticationService.CAN_RETRY_LOGIN_PROPERTY, "true");
    }
  }

  pass() {
    this.router.navigate(['/change-credentials']);
  }


  login() {

    // PRIMA DI FARE TUTTO IL RESTO, CONTROLLO SE L'UTENTE HA RAGGIUNTO IL NUMERO MAX DI TENTATIVI
    let carRetryLogin = localStorage.getItem(AuthenticationService.CAN_RETRY_LOGIN_PROPERTY);
    // console.log("carRetryLogin: ",carRetryLogin);
    if (carRetryLogin == "false") {
      let timerRetryLoginString = localStorage.getItem(AuthenticationService.TIMER_RETRY_LOGIN_PROPERTY);
      // Converto da stringa a number la data
      let timerRetryLoginNumber = parseInt(timerRetryLoginString);
      // console.log("timerRetryLogin: ",timerRetryLoginNumber);
      let canDoLogin = this.checkIfUserCanDoLogin(timerRetryLoginNumber);
      // console.log("canDoLogin: ",canDoLogin);
      if (!canDoLogin) {
        return;
      }
    }
    this.authenticationService.loginFormData(this.auth).subscribe(res => {
     // console.log('ress loginFormData', res);
      this.enableLogin();
      if (res.hasOwnProperty('force_reset_password')) {
        this.dialog.open(AlertchangePassword);
        this.authenticationService.userData = res;
      } else {
        this.authenticationService.userData = res;
        this.authenticationService.rememberMe(res)
        this.getUserProfile();
      }
    }, err => {
      console.log('loginFormData', err);
    });
  }

  getUserProfile(){
    this.authenticationService.getProfile().subscribe( res => {
      // console.log("getProfile", res);
      this.authenticationService.member_id = res.data.member_id;

      this.authenticationService.sendLogged(true);
      this.authenticationService.getUserGroups().subscribe(resp => {
        if(resp.data.groups.length == 0) {
          const dialogRef = this.dialog.open(AlertComponent);
        } else {
          this.authenticationService.savePaginazione(resp);
          // if(this.authenticationService.isAdmin() || this.userDoc === undefined){
             if(this.userDoc === undefined){
            this.router.navigate(['/user-list']);
          }else{
            this.userServ.setSelectedUser(this.userDoc);
            this.router.navigate(['/user-detail']);
          }
        }
      }, error => {
        console.error(error);
      });
    });
  }

  checkIfUserCanDoLogin(timerRetryLogin): boolean {
    let date1millis = timerRetryLogin;
    let date2millis = Date.now();

    var eventStartTime = new Date(date1millis);
    var eventEndTime = new Date(date2millis);
    var durationMillis = eventEndTime.valueOf() - eventStartTime.valueOf();

    let minutiAttesa = this.authenticationService.getDisableLoginForMinutes();
    let millisAttesa = minutiAttesa * 60000;
    let secondiRimasti = (millisAttesa - durationMillis).toString().slice(0, -3)

    // console.log("eventStartTime: ",eventStartTime)
    // console.log("eventEndTime: ",eventEndTime)
    // console.log("millisAttesa: ",millisAttesa)
    // console.log("durationMillis: ",durationMillis)

    if (durationMillis > millisAttesa) {
      // console.log("Sbloccato")
      this.enableLogin();
      return true;
    }
    else {
      // console.log("Bloccato")
      this.util.presentMessage("Hai raggiunto il numero massimo di tentativi di accesso. Devi attendere " + secondiRimasti + " secondi prima di poter riprovare.")
      return false;
    }
  }

  enableLogin() {
    // Resetto il contatore dei tentativi errati di login
    this.authenticationService.resetCountRetryError();
    localStorage.setItem(AuthenticationService.CAN_RETRY_LOGIN_PROPERTY, "true");
    localStorage.setItem(AuthenticationService.TIMER_RETRY_LOGIN_PROPERTY, undefined);
  }

  openResetPasswordDialog() {
    const dialogRef = this.dialog.open(ResetPasswordDialogComponent);
    dialogRef.afterClosed().subscribe(email => {
      if (email) {
        this.authenticationService.resetPassword(email).subscribe(res => {
          const dialogRef = this.dialog.open(ResetPasswordSuccessDialogComponent)
        }, err => {
        });
      }
    });
  }


}
