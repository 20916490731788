import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/services/user/user.service';
import { User } from 'src/models/User/user.model';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {EnvironmentService} from "src/services/environment/environment.service";
import { DocumentService } from 'src/services/document/document.service';
import { AuthenticationService } from 'src/services/auth/authentication.service';
import { UserRole, CassettaVCategory } from 'src/models/User/userDocument.model';
import { AlertDocumentComponent } from 'src/app/alert-document/alert-document.component';
import * as moment from 'moment';
import {AlertchangePassword} from "../../app/alert-change-password/alert-change-password";
import {AlertSearchComponentComponent} from "../../app/alert/alertSearch/alertSearch.component";


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {


  user:User;
  vTypeCode:string;
  pagingTotalElements: number;
  pagingNumber = 0;
  pageSize = 5;
  resultsPerPage = [5, 10, 20];
  displayedColumns = [];
  dataSource = new MatTableDataSource<User>();
  pageEvent: PageEvent = new PageEvent();
  categoriaLoyalti = false;
  date: moment.Moment;
  lastname: string = '';
  utenteHoda: boolean = false;
  pdfSrc: string = null;


  constructor(private userService: UserService, private http: HttpClient, private dialog: MatDialog, private router: Router, private docServ: DocumentService,
              public authenticationService: AuthenticationService,private env: EnvironmentService) {


  }searchUsers

  ngOnInit() {
    if (localStorage.getItem("paginazione")) {
      this.pageEvent = JSON.parse(localStorage.getItem("paginazione"));
      this.pageSize = this.pageEvent.pageSize;
      this.pagingNumber = this.pageEvent.pageIndex;
    }
    // this.countUsers();
    this.userService.passBooleanToNavBar(true);

    this.userService.passBooleanIcon(false);

    if (this.authenticationService.isAdmin()) {
      this.utenteHoda = true;
    }
  }

  refreshList(){
    this.lastname = "";
    this.date = null;
    this.pagingNumber = this.pageEvent.pageIndex;

    this.userService.countUsers().subscribe(res => {
      this.pagingTotalElements = res.itemCount;
      this.getUsers();
    }, err => {
      console.error(err);
      this.dialog.open(AlertSearchComponentComponent);
    })

  }

  searchUser() {

    this.pagingNumber = 0;

    this.userService.countUsers().subscribe(res => {
      this.pagingTotalElements = res.itemCount;
      let date: string = (this.date) ? this.date.format('YYYY-MM-DD') : '';
      // console.log("lastddddname", this.lastname)

      this.userService.searchUsers(this.vTypeCode, this.pageSize, this.pagingNumber, date, this.lastname).subscribe(res => {
        // console.log("searchUser", res);
        this.dataSource = new MatTableDataSource<User>(res);

        this.setDisplayedColumns();

      }, err => {
        console.error(err);
        this.dialog.open(AlertSearchComponentComponent);
      });

    }, err => {
      console.error(err);
    });

  }


  getTipologia(user: User): string {
    let vCategory: CassettaVCategory = null;
    if (user.VAULTS[Object.keys(user.VAULTS)[0]].vCategory){
      vCategory=user.VAULTS[Object.keys(user.VAULTS)[0]].vCategory;
    }
    else {
      return "";
    }
    switch (vCategory) {
      case CassettaVCategory.account:
        return "Account";
      case CassettaVCategory.document:
        return "Docthis.pdfSrc = URL.createObjectURL(resp)umento";
      case CassettaVCategory.ecommerce:
        return "E-Commerce";
      case CassettaVCategory.loyalty:
        return "Carta fedeltà";
      case CassettaVCategory.social:
        return "Social network";

      default:
      return "";
    }
  }

  // https://neodata-hoda-api.azurewebsites.net/v1/usersDoc?thirdPartyId=C-000000787&vTypeCode=PAY&per_page=5&page=1&date=2019-02-01&lastname=rossi


  setDisplayedColumns() {
    let roleCategory = this.authenticationService.getLoggedRoleCategory();
    if (roleCategory == UserRole.hoda) {
      this.displayedColumns = ['nome', 'cognome', 'circuito', 'tipologia-carta', 'email', 'numero-carta', 'data-richiesta', 'data-validazione'];
    }
    else{
      this.displayedColumns = ['nome', 'cognome', 'circuito', 'tipologia-carta', 'email', 'numero-carta', 'data-validazione'];
    }
    // else if (roleCategory == UserRole.loyalty) {
    //   this.displayedColumns = ['nome', 'cognome', 'circuito', 'tipologia-carta', 'email', 'numero-carta', 'data-validazione'];
    // } else if (roleCategory == UserRole.social || roleCategory == UserRole.account || roleCategory == UserRole.ecommerce) {
    //   this.displayedColumns = ['nome', 'cognome', 'circuito', 'tipologia-carta', 'email', 'numero-carta', 'data-validazione'];
    // }
  }
  getPage() {

  }

  getUsers() {
    this.userService.getUsers(this.pageSize, this.pagingNumber).subscribe(res => {
      // res = res.filter(function(el) {
      //   if (el.RGW.vAdditionalData.name.toLowerCase().indexOf("piri") > -1){
      //     return el;
      //   };
      // });
      this.dataSource = new MatTableDataSource<User>(res);

      this.setDisplayedColumns();
    }, err => {
      console.error(err);
      this.dialog.open(AlertSearchComponentComponent);
    });
  }

  countUsers() {
    this.userService.countUsers().subscribe(res => {
      this.pagingTotalElements = res.itemCount;
      this.getUsers();
    }, err => {
      console.error('Errore count', err);
      this.getUsers(); // temporaneo
    });
  }


  onPaginateChange(pageEvent: PageEvent) {
    if (this.pageSize != pageEvent.pageSize) { // se cambia il numero di elementi per pagina torno alla 0
      this.pagingNumber = 0;
    } else {
      this.pagingNumber = pageEvent.pageIndex;
    }
    this.pageSize = pageEvent.pageSize;

    //this.countUsers();

    let date: string = (this.date) ? this.date.format('YYYY-MM-DD') : '';
    this.userService.searchUsers(this.vTypeCode, this.pageSize, this.pagingNumber, date, this.lastname).subscribe(res=> {
      // console.log("searchUser", res);
      this.dataSource = new MatTableDataSource<User>(res);

      this.setDisplayedColumns();

    }, err => {
      console.error(err);
      this.dialog.open(AlertSearchComponentComponent);
    });

  }

  selectUser(user: User) {
    if(this.authenticationService.isAdmin()){
      if (user.DOCUMENT == undefined || user.DOCUMENT.vTypeCode == undefined) {
        // this.tuometodosults();
        this.dialog.open(AlertDocumentComponent);
      } else {
        this.pageEvent = { pageSize: this.pageSize, pageIndex: this.pagingNumber, length: 5 };
        localStorage.setItem("paginazione", JSON.stringify(this.pageEvent));
        this.userService.setSelectedUser(user);
        this.router.navigate(['/user-detail']);
      }
    }
  }
  // tuometodosults(){
  //   window.alert('Il documento non è presente')
  // }

  textValue(user: User, fields: string[]): string { // se i valori nella lista sono undefined allora li assegna come stringa vuota
    try {
      let element: any = user;
      for (let index = 0; index < fields.length; index++) {
        element = element[fields[index]];
        if (fields.includes('vType') && fields.includes('VAULTS') && (element[Object.keys(element)[0]].vType)!=null){
          return element[Object.keys(element)[0]].vType;
        }
        else if (fields.includes('identificativoCarta')){
          return element[Object.keys(element)[0]].vAdditionalData.identificativoCarta;
        }
        else if (fields.includes('username') && fields.includes('VAULTS')){
          if (user.VAULTS[Object.keys(user.VAULTS)[0]].vCategory==CassettaVCategory.ecommerce){
            return user.VAULTS[Object.keys(user.VAULTS)[0]].vCredentials.username;
          }
        }
      }
      if (element != undefined) {
        return String(element);
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  }
}
