import { Component, OnInit } from '@angular/core';
import { User } from 'src/models/User/user.model';
import { UserService } from 'src/services/user/user.service';
import { DocumentService, DocSide } from 'src/services/document/document.service';
import { AuthenticationService } from 'src/services/auth/authentication.service';
import { EnvironmentService } from 'src/services/environment/environment.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertPdfComponent } from 'src/app/alert-pdf/alert-pdf.component';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { ViewChild } from '@angular/core'

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

    @ViewChild(PdfViewerComponent, {static: false})
      private pdfComponent: PdfViewerComponent;

  user: User;
  urlFronte: string;
  urlRetro: string;
  description: string;
  utenteHoda: boolean = false;

  pdfSrc: string = null;
  sanitizedUrlFronte: SafeResourceUrl = "";
  sanitizedUrlRetro: SafeResourceUrl = "";

  constructor(private router: Router, private userService: UserService, private dialog: MatDialog,
    private docServ: DocumentService, private authenticationService: AuthenticationService,
    private env: EnvironmentService, public httpClient: HttpClient, private sanitizer: DomSanitizer) {

    this.userService.passBooleanToNavBar(true);
    this.userService.passBooleanIcon(true);
    this.user = userService.getSelectedUser();
    this.getInfoandCall();
  }

    pageRendered() {
       this.pdfComponent.pdfViewer.currentScaleValue = 'page-fit';
     }
    // if(this.authenticationService.isAdmin()){
    //   this.docServ.getDocument(this.user.RGW.userId, this.user.DOCUMENT.vTypeCode, DocSide.sideName1).subscribe(resp => {
    //     //this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(resp));
    //     this.pdfSrc = URL.createObjectURL(resp);
    //     /*if(this.user.DOCUMENT.vTypeCode != "PAS"){
    //         this.docServ.getDocument(this.user.RGW.userId, this.user.DOCUMENT.vTypeCode, DocSide.sideName2).subscribe(resp => {
    //         this.sanitizedUrlRetro = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(resp));
    //       });
    //     }*/
    //   });
    // }else{
    //   this.docServ.getDocumentWithToken(this.user.RGW.token, DocSide.sideName1).subscribe(resp => {
    //   this.pdfSrc = URL.createObjectURL(resp);
    //     //this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(resp));
    //     /*if(this.user.DOCUMENT.vTypeCode != "PAS"){
    //         this.docServ.getDocumentWithToken(this.user.RGW.token, DocSide.sideName2).subscribe(resp => {
    //         this.sanitizedUrlRetro = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(resp));
    //       });
    //     }*/
    //   });
    // }


  getInfoandCall(){
    this.authenticationService.getProfile().subscribe(userInfo => {
      let token_id = userInfo.data.token_id;
      let ptoken = userInfo.data.token_pass;
      let ftoken = this.user.RGW.token;
      let stoken = this.authenticationService.getUserData().access_token;
      this.httpClient.get(this.env.CTOKEN_REQUEST+token_id).subscribe(response => {
        let ctoken = response["ctoken"];
        let sess_id = response["sess_id"];
        const formData = new FormData();
        formData.append('ctoken', ctoken);
        formData.append('sess_id', sess_id);
        formData.append('utoken', token_id);
        formData.append('stoken', stoken);
        formData.append('ftoken', ftoken);
        formData.append('ptoken', ptoken);
        this.httpClient.post(this.env.DOWNLOAD_POST, formData, {responseType: 'blob'}).subscribe(response => {
          this.pdfSrc = URL.createObjectURL(response);
        }, error => {
          console.log("error in getting file "+ error.message);
          if (error.status == 410){
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = "Si è verificato un errore grave, contattare il supporto per poter visualizzare i documenti";
            let alertPDF = this.dialog.open(AlertPdfComponent, dialogConfig);
          } else {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = "Errore nel download del PDF";
            let alertPDF = this.dialog.open(AlertPdfComponent, dialogConfig);
          }
        });
      }, error => {
        console.log("error in getting ctoken "+ error.message);
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = "Errore nell'inizializzazione della sessione";
        this.dialog.open(AlertPdfComponent, dialogConfig);
      });
    }, error => {
        console.log("error in getting profile info "+ error.message);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = "Errore nel download del profilo utente";
      this.dialog.open(AlertPdfComponent, dialogConfig);
    });
  }

  getNeodataHeaders() {
    return {
      'apikeyheader': EnvironmentService.TOKEN_NEODATA,
      'accesstoken': this.authenticationService.userData.access_token,
      'refreshtoken': this.authenticationService.userData.refresh_token,
      'tscreationtoken': this.authenticationService.userData.created_at + "",
      'enabletoken': 'true'
    };
  }

  ngOnInit() {
    if (this.authenticationService.isAdmin()) {
      this.utenteHoda = true;
    }
  }

  // ifYouAccept() {
  //   this.docServ.accettaDoc(this.user.DOCUMENT.vId, 'vStatus', "active").subscribe(res => {
  //     this.router.navigate(['/user-list']);
  //   })
  // }
  //
  // ifYouDecline() {
  //   this.docServ.accettaDoc(this.user.DOCUMENT.vId, 'vStatus', "refused").subscribe(res => {
  //     this.router.navigate(['/user-list']);
  //   })
  // }

  textValue(user: User): string { // se i valori nella lista sono undefined allora li assegna come stringa vuota
    try {
      let element: any = user;
      if (element != undefined) {
        return String(element);
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  }

}
