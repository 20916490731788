import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alertSearch.component.html',
  styleUrls: ['./alertSearch.component.scss']
})
export class AlertSearchComponentComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AlertSearchComponentComponent>) { }

  ngOnInit() {
  }
  close() {
    this.dialogRef.close();
  }

}
