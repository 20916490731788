import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  // PRODUCTION - start
  // ----------------------------
  public static readonly TRANSFERS: string = "https://dpo-transfers.hoda.digital";
  private static readonly API_ENDPOINT: string = "https://api.weople.space/api/v1"; //url endpoint prod
  public static readonly CLIENT_ID: string = 'ec6d9ce81c3e9810ef18e069031c82448a3dbc0f3ba81fa97777d45c7bd0e82f';
  public static readonly CLIENT_SECRET: string = '37b2b0b8a66ff07b1f28fba6722aa4527bae9d79488efef140c5f375366abbbb';
  public static readonly ANDROID_CLIENT_ID: string = '3f8662d97578fe74cfa0e63ba256e5bee7b0f4c6e18e4eb00c0029878894ad24';
  public static readonly ANDROID_CLIENT_SECRET: string = '263b3fb7ab6ae4461dcebd606ffd22c2fd3ae422b363e0b504760d68e58344bb';
  public static readonly IOS_CLIENT_ID: string = 'ba5335e877de5bc2c0dea2c87c9d18bf3b4c8848d63c6b2668ce0cdf13708ab5';
  public static readonly IOS_CLIENT_SECRET: string = 'bff51d6ca426ec0dc6d577bebc10bf32883866e95a0c2599bbac2bf7e712e66b';

  private static readonly NEODATA_ENDPOINT: string = 'https://api.weople.space/v1'; //url endpoint prod backend neodata
  public static readonly TOKEN_NEODATA: string = 'O4IBT9vHSkYYEC0kmDM2rQi5J8u70ZNs'; // prod
  // // // ----------------------------
  // PRODUCTION - stops
  //

  // // STAGING - start
  // // ----------------------------
  // public static readonly TRANSFERS: string = "https://staging.transfers.weople.space";
  // private static readonly API_ENDPOINT: string = 'https://weople-staging.demostellar.com'; // url endpoint staging
  // public static readonly CLIENT_ID: string = '4ae1f1e19ef394fd7299fbeb22a1ff9172510dc325a8be43aac575804ec58a95';
  // public static readonly CLIENT_SECRET: string = 'bf88a26b42c3fcb253422ae472bf02d65d0ea52d9d8b9ab1fbf9428cf14b71cd';
  // public static readonly ANDROID_CLIENT_ID: string = '6bd8b5c8c511cd496a2258632e20f24dd7bdd6f090eae3b9ad90fb17393c69b5';
  // public static readonly ANDROID_CLIENT_SECRET: string = '5decd77a60f620c0bbe755cad37f4c2c8a2c5500cb2bbcda21ba4c9502032f78';
  // public static readonly IOS_CLIENT_ID: string = '18ccac95ea5db52f909187a30ddc2425c86afb17032cbc79656d773e6c4dc077';
  // public static readonly IOS_CLIENT_SECRET: string = '66ff75ccfb32c1e258ac86f81394554756718b76af6c84565e176043e32727ea';
  // private static readonly NEODATA_ENDPOINT: string = 'https://api-neodata-dev.azurewebsites.net/v1'; // url endpoint staging backend neodata
  // public static readonly TOKEN_NEODATA: string = 'RElT4gN7hTdLjZX1qwLTz0i0FnvNnwiM'; // staging
  // // ----------------------------
  // // STAGING - stop


  // https://neodata-hoda-api.azurewebsites.net/v1/usersDoc?thirdPartyId=C-000000787&vTypeCode=PAY&per_page=5&page=1&date=2019-02-01&lastname=rossi
 // https://neodata-hoda-api.azurewebsites.net/v1/userDoc?thirdPartyId=C-000021280&vTypeCode=Hoda&per_page=5&page=1&lastname=Lara

  readonly LOGIN = EnvironmentService.API_ENDPOINT + '/oauth/token';
  readonly GET_USER = EnvironmentService.API_ENDPOINT + '/profile'; // GET
  readonly FORGOT_PASSWORD = EnvironmentService.API_ENDPOINT + '/passwords'; // POST
  readonly CHANGE_PASSWORD = EnvironmentService.API_ENDPOINT + '/profile/change_password'; // PUT
  readonly GET_GROUP = EnvironmentService.API_ENDPOINT + '/groups'; // GET
  // readonly SEARCHUSER = EnvironmentService.SEARCH ; // GET
  readonly GET_DOCUMENT= EnvironmentService.NEODATA_ENDPOINT +'/document';
  readonly USER_DOC= EnvironmentService.NEODATA_ENDPOINT +'/usersDoc';
  readonly CTOKEN_REQUEST = EnvironmentService.TRANSFERS + "/?f=";
  readonly DOWNLOAD_POST = EnvironmentService.TRANSFERS + "/85xbf.php";

  urlGroups(group_id: string, description: string ) {
    return EnvironmentService.NEODATA_ENDPOINT + '/usersDoc?thirdPartyId=' + group_id + '&vTypeCode=' + description;
  }  // GET


  accettaRifiuta(vault_id: number ){
    return EnvironmentService.NEODATA_ENDPOINT +'/vault/' + vault_id +'/attributes'}

    countUsers(group_id: string, description: string ) {
      return EnvironmentService.NEODATA_ENDPOINT + '/usersDoc/count?thirdPartyId=' + group_id + '&vTypeCode=' + description;
    }

}
// https://neodata-hoda-api.azurewebsites.net/v1/usersDoc?thirdPartyId=C-000000787&vTypeCode=PAY&per_page=5&page=1&date=2019-02-01&lastname=rossi
