import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { LoginComponent } from 'src/pages/login/login.component';
import { AuthenticationService } from 'src/services/auth/authentication.service';
import { EnvironmentService } from 'src/services/environment/environment.service';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResetPasswordDialogComponent } from 'src/pages/login/dialog/reset-password-dialog/reset-password-dialog.component';
import { UserListComponent } from 'src/pages/user-list/user-list.component';
import { UserService } from 'src/services/user/user.service';
import { UserDetailComponent } from 'src/pages/user-detail/user-detail.component';
import { NavbarComponent } from 'src/shared/navbar/navbar.component';
import { GuardService as AuthGuard } from 'src/services/guard/guard.service';
import { CustomHttpInterceptor } from 'src/interceptors/custom-http-interceptor';
import { DocumentService } from 'src/services/document/document.service';
import { AlertComponent } from './alert/alert.component';
import { ResetPasswordSuccessDialogComponent } from 'src/pages/login/dialog/reset-password-success-dialog/reset-password-success-dialog.component';
import { UserDetailDialogComponent } from 'src/pages/user-list/dialog/user-detail-dialog/user-detail-dialog.component';
import { AlertDocumentComponent } from './alert-document/alert-document.component';
import { UtilService } from 'src/util/utilService';
import { AlertchangePassword } from './alert-change-password/alert-change-password';
import { ChangeCredentialsComponent } from 'src/pages/change-credentials/change-credentials.component';
import { MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {AlertSearchComponentComponent} from "./alert/alertSearch/alertSearch.component";
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AlertPdfComponent } from './alert-pdf/alert-pdf.component';

const appRoutes: Routes = [
  { path: 'index.html', redirectTo: '/login', pathMatch: 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'user-list', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'user-detail', component: UserDetailComponent, canActivate: [AuthGuard] },
  { path: 'change-credentials', component: ChangeCredentialsComponent}
];
const MY_DATE_FORMATS = {
  parse: {
      dateInput: 'DD/MM/YYYY',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
 };


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordDialogComponent,
    UserListComponent,
    UserDetailComponent,
    NavbarComponent,
    AlertComponent,
    AlertSearchComponentComponent,
    ResetPasswordSuccessDialogComponent,
    UserDetailDialogComponent,
    AlertDocumentComponent,
    AlertchangePassword,
    ChangeCredentialsComponent,
    AlertPdfComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    PdfViewerModule
  ],
  entryComponents: [
    ResetPasswordDialogComponent,
    ResetPasswordSuccessDialogComponent,
    AlertComponent,
    UserDetailDialogComponent,
    AlertDocumentComponent,
    AlertchangePassword,
    AlertSearchComponentComponent,
    AlertPdfComponent
  ],
  providers: [AuthenticationService, EnvironmentService, UserService,DocumentService, UtilService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,

    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: 'UserDoc', useValue: (<any> window).USER },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
