import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: 'app-alert-pdf',
  templateUrl: './alert-pdf.component.html',
  styleUrls: ['./alert-pdf.component.css']
})
export class AlertPdfComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AlertPdfComponent>) { }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
