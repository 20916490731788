
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert',
  templateUrl: './alert-change-password.html',
  styleUrls: ['./alert-change-password.scss']
})
export class AlertchangePassword implements OnInit {

  constructor(private dialogRef: MatDialogRef<AlertchangePassword>, private router: Router) { }

  ngOnInit() {
  }
  goToChangeCredentials(){
    this.router.navigate(['/change-credentials']);
    this.dialogRef.close();
  }

}
