import {Injectable} from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../environment/environment.service';
import { Auth } from 'src/models/auth.model';
import { UserData } from 'src/interfaces/userdata.interface';
import { User } from 'src/models/User/user.model';
import { CassettaVTypeCode, UserRole } from 'src/models/User/userDocument.model';
import { PaginazioneModel } from 'src/models/paginazione.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from 'src/app/alert/alert.component';




@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  static CAN_RETRY_LOGIN_PROPERTY: string = 'canRetryLogin';
  static TIMER_RETRY_LOGIN_PROPERTY: string = 'timerRetryLogin';
  static REMEMBER_ME:string = 'remember_me';
  private paginazione: PaginazioneModel;
  user = User;


  static instance: AuthenticationService;

  userData: UserData = {
    info: null,
    isLogged: false,
    access_token: null,
    refresh_token: null,
    created_at: null,
  };

  public member_id = "";

  public userRole = {
    description: "",
    group_id: ""

  };

  private countLoginRetryError: number = 1; //Counter numero di tentativi errore login
  private maxRetryLogin: number = 3; //Numero di tentativi dopo il quale viene bloccata la login
  private disableLoginForMinutes: number = 2; //Minuti di attesa prima di sbloccare la login


  private hasLoggedObservable = new Subject<boolean>();
  private logged: boolean = false;

  constructor(private env: EnvironmentService, private httpClient: HttpClient, private router: Router,private dialog: MatDialog) {
    return AuthenticationService.instance = AuthenticationService.instance || this;
  }

  changePassword(currentPassword , password, passwordConfirmation) {

    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.getAccessToken()
    };

    let params = {
      access_token: this.getAccessToken()
    };

    let credentials = {

      current_password:currentPassword,
      password:password,
      password_confirmation:passwordConfirmation

    }

    return this.httpClient.put(this.env.CHANGE_PASSWORD, credentials, { headers: headers});
  }

  loginFormData(auth: Auth): Observable<any> {

    let headers = {
      'Accept': 'application/json',
    };

    let body = new FormData();
    body.append('email', auth.email);
    body.append('password', auth.password);
    body.append('client_id', EnvironmentService.CLIENT_ID);
    body.append('client_secret', EnvironmentService.CLIENT_SECRET);
    body.append('grant_type', 'password');

    return this.httpClient.post(this.env.LOGIN, body, { headers: headers });
  }

  getNeodataHeaders() {
    return {
       'apikeyheader': EnvironmentService.TOKEN_NEODATA,
       'accesstoken': this.userData.access_token,
       'refreshtoken': this.userData.refresh_token,
       'tscreationtoken': this.userData.created_at + "",
       'enabletoken': 'true'
     };
   }

   getPaginazione():PaginazioneModel{
    return this.paginazione
   }
   setPaginazione(paginazione:PaginazioneModel){
    this.paginazione = paginazione;
   }

   savePaginazione(resp){
    if (resp.data.groups != undefined && resp.data.groups.length > 0) {
      this.userRole.description = resp.data.groups[0].description;
      let paginazione = JSON.parse(localStorage.getItem("paginazione"));
      if(paginazione){
        paginazione.pageIndex = 0;
        localStorage.setItem("paginazione", JSON.stringify(paginazione));
      } else {
        paginazione = {
          pageSize : 5,
          pageIndex : 0,
          length: 5
        };
        localStorage.setItem("paginazione",JSON.stringify(paginazione));
      }
    }
    // else {
    //   const dialogRef = this.dialog.open(AlertComponent);
    // }
   }

  resetPassword(email: string) {

    let headers = {
      'Accept': 'application/json',
    };

    let credentials = {
      email: email,
      client_id: EnvironmentService.CLIENT_ID,
      client_secret: EnvironmentService.CLIENT_SECRET
    };

    return this.httpClient.post(this.env.FORGOT_PASSWORD, credentials, { headers: headers });
  }

  getProfile(): Observable<any> {

    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.getAccessToken()
    };

    return this.httpClient.get(this.env.GET_USER, { headers: headers});
  }

  /**
   * Recupera i gruppi dell'utente
   */
  getUserGroups(): Observable<any> {
    let headers = {
      'Accept': 'application/json',
      'Authorization': 'Token '+ this.getAccessToken()
    };
    return this.httpClient.get(this.env.GET_GROUP, {headers: headers});
  }


  rememberMe(data){
    let { access_token, created_at, refresh_token} = data;
    localStorage.setItem(AuthenticationService.REMEMBER_ME,
      `${access_token}|${refresh_token}|${created_at}`);
  }

  doYouRememberMe(): boolean{
    let remember_me = localStorage.getItem(AuthenticationService.REMEMBER_ME);
    if(remember_me != undefined){
      let remember_splitted = remember_me.split("|");
      this.userData.access_token = remember_splitted[0];
      this.userData.refresh_token = remember_splitted[1];
      this.userData.created_at = parseInt(remember_splitted[2]);
      return true;
    }
    return false;
  }

  dontRememberMe(){
    localStorage.removeItem(AuthenticationService.REMEMBER_ME);
  }

  getLoggedRoleCategory(): UserRole  {
    let loggedRoleCategory: UserRole;
    switch (this.userRole.description) {
      case CassettaVTypeCode.facebook:
        loggedRoleCategory = UserRole.social;
        break;
        case CassettaVTypeCode.instagram:
        loggedRoleCategory = UserRole.social;
        break;
        case CassettaVTypeCode.twitter:
        loggedRoleCategory = UserRole.social;
        break;
        case CassettaVTypeCode.linkedin:
        loggedRoleCategory = UserRole.social;
        break;
        case CassettaVTypeCode.google:
        loggedRoleCategory = UserRole.account;
        break;
        case CassettaVTypeCode.apple:
        loggedRoleCategory = UserRole.account;
        break;
        case CassettaVTypeCode.carrefour:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.conad:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.coop:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.esselunga:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.gigante:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.iper:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.unes:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.auchan:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.ikeaFamily:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.decathlon:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.mediaworld:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.laTuaCard:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.panorama:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.coin:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.unieuro:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.acquaESapone:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.feltrinelli:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.pewex:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.cisalfaSport:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.md:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.altro:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.altLoyalty:
        loggedRoleCategory = UserRole.loyalty;
        break;
        case CassettaVTypeCode.amazon:
        loggedRoleCategory = UserRole.ecommerce;
        break;
        case CassettaVTypeCode.altEcommerce:
        loggedRoleCategory = UserRole.ecommerce;
        break;
        case UserRole.hoda:
        loggedRoleCategory = UserRole.hoda;
        break;

        default:
        loggedRoleCategory = UserRole.loyalty;
    }
    return loggedRoleCategory;
  }


  // INIZIO METODI LOGGED

  sendLogged(status: boolean) {
    this.logged = status;
    this.hasLoggedObservable.next(status);
  }

  clearLogged() {
    this.hasLoggedObservable.next();
  }
  getUserData(): UserData {
    return this.userData;
  }

  getLoggedObservable(): Observable<any> {
    return this.hasLoggedObservable.asObservable();
  }

  hasLogged(): boolean {
    return this.logged;
  }
  getAccessToken() {
    return this.userData.access_token;
  }

  isAdmin(){
    return this.getLoggedRoleCategory()=='Hoda';
  }

// FINE METODI LOGGED

//INIZIO METODI BLOCCO LOGIN DOPO UN TOT DI TENTATIVI

  incrementCountRetryError() {
    this.countLoginRetryError+=1;
   // console.log(this.countLoginRetryError)
  }

  resetCountRetryError(){
    this.countLoginRetryError = 0;
  }

  getCountRetryError(): number {
    return this.countLoginRetryError;
  }

  getMaxRetryLogin(): number {
    return this.maxRetryLogin;
  }

  getDisableLoginForMinutes(): number {
    return this.disableLoginForMinutes;
  }

//FINE METODI BLOCCO LOGIN DOPO UN TOT DI TENTATIVI

}
