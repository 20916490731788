import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/services/auth/authentication.service';
import { Router } from '@angular/router';
import { UserService } from 'src/services/user/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  confIconNav = false

  hasLogged: boolean;
  visibileNav: boolean = false;
  constructor(private authService: AuthenticationService, private router: Router, userService: UserService) {
    this.authService.getLoggedObservable().subscribe(res => {
      this.hasLogged = res;
    })

    userService.confBoolNavBar.subscribe(bool => {
      setTimeout(() => this.visibileNav = bool, 0)
    });

    userService.confIconNav.subscribe(bool => {
      this.confIconNav = bool;
    });

  }

  ngOnInit() {
  }

  logout() {
    this.authService.sendLogged(false);
    this.authService.dontRememberMe();
    this.router.navigate(['']);
  }

  backToList(){
    this.router.navigate(['/user-list']);
  }

}
