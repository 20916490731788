import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { UserService } from 'src/services/user/user.service';

declare var window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{

  title = 'weoplePdg';
  isPageLogin: boolean = true;
  href: string = "";
  paramEmail: string;

  constructor(private router: Router,private userService:UserService,private route: ActivatedRoute){

  }

  ngOnInit() {
    if (window.emailPartner) {
      this.router.navigate(['/login', { email: window.emailPartner }]);
    }

  }


}

