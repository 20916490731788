import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/services/auth/authentication.service';
import { UtilService } from 'src/util/utilService';
import { Auth } from 'src/models/auth.model';

@Component({
  selector: 'app-change-credentials',
  templateUrl: './change-credentials.component.html',
  styleUrls: ['./change-credentials.component.scss']
})
export class ChangeCredentialsComponent implements OnInit {

  passwordConfirmation: string;
  password: string;
  currentPassword: string;
  auth: Auth;
  constructor(private router: Router, private authenticationService: AuthenticationService, private util: UtilService, private route: ActivatedRoute) {
    
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  changePasswordOk() {
    //console.log("paramentri", this.passwordConfirmation, this.password, this.currentPassword);
    this.authenticationService.changePassword(this.passwordConfirmation, this.password, this.currentPassword).subscribe(resp => {
      this.util.presentMessage("La password è stata cambiata con successo");
      this.authenticationService.getProfile().subscribe(res => {
        this.authenticationService.member_id = res.data.member_id;
        this.authenticationService.sendLogged(true);
        this.authenticationService.getUserGroups().subscribe(resp => {
          this.authenticationService.savePaginazione(resp);
          this.router.navigate(['/user-list']);
        }, error => {
          console.error(error);
        });
      }, err => {
        console.error("error getProfile-changePasswordOk", err);
      });
    }, err => {
      console.error("errore changePasswordOk", err)
    })
  }

  ngOnInit() {
    // this.route.params.subscribe(params => {
    //   this.auth.email = params['email'];
    // })
  }

}
