import {Injectable, Injector} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {User} from 'src/models/User/user.model';
import {HttpClient} from "@angular/common/http";
import {EnvironmentService} from "../environment/environment.service";
import { AuthenticationService } from '../auth/authentication.service';


@Injectable({
  providedIn: 'root'
})

export class UserService {

  static instance: UserService;
  authenticationService: AuthenticationService;
  private boolNavBar: Subject<boolean> = new Subject<boolean>();
  confBoolNavBar: Observable<boolean> = this.boolNavBar.asObservable();

  private boolIconNavBar: Subject<boolean> = new Subject<boolean>();
  confIconNav: Observable<boolean> = this.boolIconNavBar.asObservable();


  private selectedUser: User;

  lastname: string;

  constructor(private httpClient: HttpClient, private env: EnvironmentService, public injector: Injector,private auth: AuthenticationService) {

    setTimeout(() => this.authenticationService = injector.get(AuthenticationService));
    return UserService.instance = UserService.instance || this;
  }

  getNeodataHeaders() {
   return {
      'apikeyheader': EnvironmentService.TOKEN_NEODATA,
      'accesstoken': this.authenticationService.userData.access_token,
      'refreshtoken': this.authenticationService.userData.refresh_token,
      'tscreationtoken': this.authenticationService.userData.created_at + "",
      'enabletoken': 'true'
    };
  }

  // LISTA PAGINATA
  // getUsers(pageNumber: number, pageSize: number): Observable<Page<User[]>> {
  //     let url = this.env.urlGroups(this.authenticationService.member_id , this.authenticationService.userRole.description);
  //     return this.httpClient.get<Page<User[]>>(url, {headers: this.getNeodataHeaders()}); 
  // }

  countUsers(){
    let url= this.env.countUsers(this.authenticationService.member_id , this.authenticationService.userRole.description);

    return this.httpClient.get<userCounter>(url, {headers: this.getNeodataHeaders()});
  }


  getUsers(pageSize: number, pageNumber: number): Observable<User[]> {
    // test
    // this.authenticationService.userRole.description = 'Alitalia'
    let url = this.env.urlGroups(this.authenticationService.member_id , this.authenticationService.userRole.description);
    let params = {
      per_page: pageSize.toString(),
      page: (pageNumber +1).toString() // indice pagina neodata parte da 1
    }
    return this.httpClient.get<User[]>(url, {headers: this.getNeodataHeaders(), params: params}); 
}

  searchUsers(vTypeCode:string, pageSize: number, pageNumber: number,date: any, lastname: string): Observable<User[]> {
    let headers = {
      'apikeyheader': EnvironmentService.TOKEN_NEODATA,
      'accesstoken': this.auth.getUserData().access_token,
      'refreshtoken': this.auth.getUserData().refresh_token,
      'tscreationtoken': this.auth.getUserData().created_at + ""
    };

    let params = {
      thirdPartyId: this.authenticationService.member_id,
      vTypeCode: this.authenticationService.userRole.description,
      per_page: pageSize.toString(),
      page: (pageNumber + 1).toString(), // indice pagina neodata parte da 1
      date: date,
      lastname: lastname
    };

    return this.httpClient.get<User[]>(this.env.USER_DOC, {headers: headers, params: params});
  }



  getSelectedUser(): User {
    return this.selectedUser;
  }

  setSelectedUser(user: User) {
    this.selectedUser = user;
  }
  passBooleanToNavBar(bool:boolean){
    this.boolNavBar.next(bool);
   }
   
   passBooleanIcon(bool:boolean){
    this.boolIconNavBar.next(bool);
   }


}


export interface userCounter {
  itemCount: number;
}
// https://neodata-hoda-api.azurewebsites.net/v1/usersDoc?thirdPartyId=C-000000787&vTypeCode=PAY&per_page=5&page=1&date=2019-02-01&lastname=rossi
