export class UserDocument {
  
    
        vCredentials:{
    
        };
        vDataGrabbed:boolean;
        vMain:boolean;
        userId:string;
        vId:number;
        vLockMarketing:boolean;
        vTypeCode:CassettaVTypeCode;
        vAdditionalData:{
          uploadDate:Date;
          sideName1:string;
          sideName2:string;
          validationDate:Date;
        };
        vType:CassettaVType;
        vLockThirdParty:boolean;

        vGrants:{
    
        };
        vExportRequested:boolean;
        vStatus:CassettaStatus;
        vCategory:CassettaVCategory;
      }

      export class VCredentials {
        username: string;
        password: string;
        access_token: string;
        scopes: string[];
    }

      export enum CassettaVCategory {
        social = 'social',
        loyalty = 'loyalty',
        account = 'account',
        ecommerce = 'ecommerce',
        document = 'document'
    }

      export enum CassettaVTypeCode {
        // SOCIAL
        facebook = 'FB',
        instagram = 'IG',
        twitter = 'TW',
        linkedin = 'LI',
        // ACCOUNT
        google = 'GO',
        apple = 'AP',
        // LOYALTY
        carrefour = 'CAR',
        conad = 'CON',
        coop = 'COO',
        esselunga = 'ESS',
        gigante = 'GIG',
        iper = 'IPE',
        unes = 'UNE',
        auchan = 'AUC',
        ikeaFamily = 'IKE',
        decathlon = 'DEC',
        mediaworld = 'MED',
        laTuaCard = 'LTC',
        panorama = 'PAN',
        coin = 'COI',
        unieuro = 'UNI',
        acquaESapone = 'AES',
        feltrinelli = 'FEL',
        pewex = 'PEW',
        cisalfaSport = 'CIS',
        md = 'MDX',
        // ECOMMERCE
        amazon = 'ZN',
    
        //  DOCUMENTS
        identityCard = "CID",
        drivingLicense = "PAT",
        passport = "PAS",
        residencyPermit = "SOG",
    
        altro = 'ALT',
        altLoyalty = 'ALT_LOYALTY',
        altEcommerce = 'ALT_ECOMMERCE'
    }
    export enum CassettaVType {
      // SOCIAL
      facebook = 'Facebook',

      instagram = 'Instagram',
      twitter = 'Twitter',
      linkedin = 'LinkedIn',
      // ACCOUNT
      google = 'Google',
      apple = 'Apple',
      // LOYALTY
      carrefour = 'Carrefour',
      conad = 'Conad',
      coop = 'Coop',
      esselunga = 'Esselunga',
      gigante = 'Gigante',
      iper = 'Iper',
      unes = 'Unes',
      auchan = 'Auchan',
      ikeaFamily = 'Ikea Family',
      decathlon = 'Decathlon',
      mediaworld = 'Mediaworld',
      laTuaCard = 'LaTuaCard',
      panorama = 'Panorama',
      coin = 'Coin',
      unieuro = 'Unieuro',
      acquaESapone = 'Acqua&Sapone',
      feltrinelli = 'LaFeltrinelli',
      pewex = 'Pewex',
      cisalfaSport = 'Cisalfa Sport',
      md = 'MD',
      // ECOMMERCE
      amazon = 'Amazon',
      //  DOCUMENTS
      identityCard = "Carta d'Identità",
      drivingLicense = "Patente di guida",
      passport = "Passaporto",
      residencyPermit = "Permesso di soggiorno",
  
      altro = 'Altro'
  }
  export enum CassettaStatus {
    active = 'active',
    inactive = 'suspended',
}

export enum UserRole {
  social = 'social',
  account = 'account',
  loyalty = 'loyalty',
  ecommerce = 'ecommerce',
  hoda = 'Hoda'
}
