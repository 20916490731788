import { MatSnackBar } from "@angular/material/snack-bar";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { EnvironmentService } from "src/services/environment/environment.service";
import { AuthenticationService } from "src/services/auth/authentication.service";
import { HttpClient} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class UtilService {

    constructor(private snackBar: MatSnackBar,private env: EnvironmentService,private auth: AuthenticationService,public httpClient: HttpClient) {
    }

    presentMessage(message) {
        if (message == null) {
            return;
        }
        this.snackBar.open(message, '', {
            duration: 5000,
            panelClass: 'failure',
        });
    }

    

}
