import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { tap, finalize } from 'rxjs/operators';
import { AuthenticationService } from "src/services/auth/authentication.service";
import { UtilService } from "src/util/utilService";

export interface Error {
    code: Number;
    message: string;
    name: string;
}

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

    constructor(private snackBar: MatSnackBar, private auth: AuthenticationService, private util: UtilService) { }

    counterChiamate = 0;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.startLoader();
        return next.handle(request)
            .pipe(
                tap(
                    success => {
                    },
                    error => {
                        console.error(error);
                        //console.log(error.error.code)
                        if (error.error.code != null) {
                            switch (error.error.code) {
                                case 4005:
                            
                                this.auth.incrementCountRetryError();
                                if(this.auth.getCountRetryError()<=this.auth.getMaxRetryLogin()){
                                    this.util.presentMessage("Credenziali errate");
                                }
                                else{
                                    this.util.presentMessage("Hai raggiunto il numero massimo di tentativi di accesso.");     
                                    let canRetryLogin = localStorage.getItem(AuthenticationService.CAN_RETRY_LOGIN_PROPERTY);
                                    if (canRetryLogin=="true"){
                                        //console.log("Blocco attivato");
                                        localStorage.setItem(AuthenticationService.TIMER_RETRY_LOGIN_PROPERTY,  Date.now().toString());
                                        localStorage.setItem(AuthenticationService.CAN_RETRY_LOGIN_PROPERTY, "false");
                                    }
                                }
                                return;
                        }
                    }
                        this.util.presentMessage(error.error.message);
                    }
                ),
                finalize(() => {
                    this.stopLoader();

                })
            );
    }

    startLoader() {
        this.counterChiamate++;
        if (this.counterChiamate == 1) {
            document.body.classList.add("during-loading");
            document.getElementById('loader').classList.remove('hide');
        }
    }

    stopLoader() {
        this.counterChiamate--;
        if (this.counterChiamate == 0) {
            document.body.classList.remove("during-loading");
            document.getElementById('loader').classList.add('hide');
        }
    }


}
