import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {

  email: string;
  
  constructor(private dialogRef: MatDialogRef<ResetPasswordDialogComponent>) { }

  ngOnInit() {
  }

  close(confirm: boolean) {
    if (confirm) {
      this.dialogRef.close(this.email);
    } else {
      this.dialogRef.close();
    }
  }

}
