import { UserRGW } from './userRGW.model';
import { UserVault } from './userVault.model';
import { UserDocument } from './userDocument.model';
import { Vadditional } from './vAdditionalData.model';

export class User {
  
  RGW: UserRGW;
  VAULTS: UserVault;
  DOCUMENT: UserDocument;
  userId: string;
  vAdditionalData: Vadditional;

}





// [
//   {"RGW":
//     {"vCredentials":
//       {"email":"concetta.barone@neodatagroup.com"},
//       "vDataGrabbed":true,
//       "vMain":true,
//       "userId":"C-000000314",
//       "vId":362,
//       "vLockMarketing":false,
//       "vTypeCode":"RGW",
//       "vAdditionalData":{
//         "stellarCreation":"2018-11-28T16:56:03.000Z",
//         "privacyPolicy":"true",
//         "name":"Concetta",
//         "lastname":"Barone",
//         "relative":"false"
//       },
//       "vType":"Registrazione Weople",
//       "vLockThirdParty":true,"vGrants":{},
//   "vExportRequested":true,
//   "vStatus":"active",
//   "vCategory":"weople"
//   },
//   "VAULT":{
//     "vCredentials":{
//     },
//     "vDataGrabbed":false,
//     "vMain":true,
//     "userId":"C-000000314",
//     "vId":213,
//     "vLockMarketing":false,
//     "vTypeCode":"ESS",
//     "vAdditionalData":{
//       "identificativoCarta":"874584554",
//       "intestatario":"CB"
//     },
//     "vType":"Esselunga",
//     "vLockThirdParty":false,
//     "vGrants":{

//     },
//     "vExportRequested":false,
//     "vStatus":"active",
//     "vCategory":"loyalty"
//   },
//   "DOCUMENT":{
//     "vCredentials":{

//     },
//     "vDataGrabbed":false,
//     "vMain":true,
//     "userId":"C-000000314",
//     "vId":165,
//     "vLockMarketing":false,
//     "vTypeCode":"PAT",
//     "vAdditionalData":{
//       "uploadDate":"29/11/2018 09:53:57",
//       "sideName1":"0ff009c7df0323d8c4bf198a677812f5.png",
//       "validationDate":"29/11/2018 09:58:10"
//     },
//     "vType":"Patente",
//     "vLockThirdParty":false,
//     "vGrants":{

//     },
//     "vExportRequested":false,
//     "vStatus":"active",
//     "vCategory":"document"
//   },
//   "userId":"C-000000314"
// }
// ]
