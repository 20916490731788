import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentService } from "../environment/environment.service";
import { AuthenticationService } from "../auth/authentication.service";
import { Observable } from "rxjs";
import { User } from "src/models/User/user.model";
import { map } from "rxjs/operators";

export enum DocSide {
  sideName1 = '1',
  sideName2 ='2'
}


@Injectable()
export class DocumentService{

  user: User;

    headers = {
      'apikeyheader': EnvironmentService.TOKEN_NEODATA,
      'accesstoken': this.auth.getUserData().access_token,
      'refreshtoken': this.auth.getUserData().refresh_token,
      'tscreationtoken': this.auth.getUserData().created_at + "",
      'enabletoken': 'true',
      'Accept':'image/png'
    };

    constructor(public httpClient: HttpClient, private env: EnvironmentService, private auth: AuthenticationService) {

    }
    getNeodataHeaders() {
      return {
         'apikeyheader': EnvironmentService.TOKEN_NEODATA,
         'access-token': this.auth.userData.access_token,
         'refreshtoken': this.auth.userData.refresh_token,
         'tscreationtoken': this.auth.userData.created_at + "",
         'enabletoken': 'true'
       };
     }



    getDocument(userId:string, docType:string, docSide: string): Observable<any> {
      let params = new HttpParams()
      .set('access_token', this.auth.userData.access_token)
      .set('userId', userId )
      .set('docType', docType)
      .set('docSide',docSide );

      return this.httpClient.get(this.env.GET_DOCUMENT,{headers:this.headers, params: params, responseType: 'blob'}).pipe(
        map(
          response => {
            if (response instanceof Response) {
              return response.blob();
            }
            return response;
          }
        )
      );
    }

    getDocumentWithToken(token:string, docSide: string): Observable<any> {
      let params = new HttpParams()
      //.set('access_token', this.auth.userData.access_token)
      .set('token', token )
      .set('docSide',docSide );

      return this.httpClient.get(this.env.GET_DOCUMENT,{headers:this.headers, params: params, responseType: 'blob'}).pipe(
        map(
          response => {
            if (response instanceof Response) {
              return response.blob();
            }
            return response;
          }
        )
      );
    }


    accettaDoc(vId:number, vStatus:string, value:string){


      let params= new HttpParams()
        .set('attribute', vStatus)
        .set('value', value)

        let headers = {
          'apikeyheader': EnvironmentService.TOKEN_NEODATA,
          'accesstoken': this.auth.getUserData().access_token,
          'refreshtoken': this.auth.getUserData().refresh_token,
          'tscreationtoken': this.auth.getUserData().created_at + "",
          'enabletoken': 'false'
        };


    let url = this.env.accettaRifiuta(vId)
    return this.httpClient.put(url, {},{headers: headers , params:params})
    }

}
